<template>
	<v-dialog v-model="dialog" persistent max-width="1000px">
		<v-card>
			<v-card-title
				class="d-flex justify-space-between border-bottom primary pt-2 pb-1"
			>
				<span class="subtitle-1 white--text">Уведомления</span>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon color="white">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>
			<v-card-text v-else>
				<v-container v-if="!isCreate">
					<v-row v-if="!isEditing">
						<v-col cols="5">
							<p class="notify-name black--text mt-3">{{notification.name}}</p>
							
							<p class="ma-0 mt-2">{{item.created_at | timestamp(true)}}</p>
						</v-col>
						<v-col cols="7" style="display: flex; justify-content: end;">
							<v-chip
								color="primary"
								outlined
								style="border-radius: 4px;"
							>
								{{item.type.name}}
							</v-chip>
						</v-col>
					</v-row>
					<v-row v-if="!isEditing">
						<!-- Выбор адреса для рекламы и уведомления жителей -->
						<v-col
							class="px-0 pb-0"
							cols="12"
						>
						<span class="subtitle-2 font-weight-light grey--text ml-1">Адрес</span>
						<v-select
							v-model="notification.houses_ids"
							placeholder="Выберите адрес"
							class="border-all chips-custom pointer-none"
							solo
							dense
							multiple
							hide-details
							flat
							chips
							required
							item-value="id"
							item-text="apartment_address"
							:items="houses"
						></v-select>
						</v-col>
						<v-col
							class="px-0 pb-0"
							cols="12"
						>
							<p class="custom-dark ft-14 ml-2">{{notification.description}}</p>
						</v-col>
						<!-- Выбор адреса для персональных уведомлений -->
						<!-- <NotificationsTableModalPersonal /> -->
						<ImagesList :images="imagesInfo" />
							<v-row>
								<v-col v-for="(file, i) in item.files" :key="i" cols="auto" class="position-relative file-container">
									<div v-if="file.type !== 'image'" class="image-wrapper">
										<img src="@/assets/file.svg" class="notify-image">
										<p class="ma-0">
											<a @click="downloadFile(file)">{{ file.name }}</a>
										</p>
										<v-btn @click="deleteLocalFile(file.type, i, file.id)" icon color="red" class="delete-btn">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</div>
								</v-col>
							</v-row>
					</v-row>
					<v-row v-else>
					<!-- Тип уведомления -->
						<v-col class="pb-0 px-0" cols="12">
							<v-select
								v-model="notification.type_id"
								placeholder="Выберите тип уведомления"
								class="border-all"
								:menu-props="{ offsetY: true }"
								solo
								dense
								hide-details
								flat
								required
								item-value="sys_name"
								item-text="name"
								:items="[...notificationTypes]"
							></v-select>
						</v-col>
						<v-col v-if="base_role === 'dispatcher'" class="pb-0 px-0" cols="12">
							<v-select
								v-model="company_id"
								placeholder="Выберите УК"
								:menu-props="{ offsetY: true }"
								class="border-all"
								solo
								dense
								hide-details
								flat
								required
								item-value="id"
								item-text="name"
								:items="kskListConfirm"
							></v-select>
						</v-col>
						<v-col class="pb-0 px-0" cols="12">
							<Autocomplete
								v-model="notification.houses_ids"
								placeholder="Выберите адреса"
								class="border-all pt-0"
								hide-details
								flat
								solo
								dense
								clearable
								deletable-chips
								multiple
								required
								select-all="true"
								item-text="apartment_address"
								item-value="id"
								:filter="addressFilter"
								:items="base_role === 'dispatcher' ? dispatcherHouses : houses"
							>
							</Autocomplete>
						</v-col>
						<!-- Тема уведомления -->
						<v-col class="px-0 pb-0" cols="12">
							<v-text-field
								v-model="notification.name"
								placeholder="Тема уведомления"
								class="border-all"
								solo
								dense
								hide-details
								flat
								required
							></v-text-field>
						</v-col>
						<!-- Текст уведомления -->
						<v-col class="px-0" cols="12">
							<v-textarea
								v-model="notification.description"
								class="border-all"
								height="200px"
								no-resize
								solo
								hide-details
								flat
								dense
							></v-textarea>
						</v-col>
						<!-- Файлы -->
						<v-col class="px-0" cols="12">
						<!-- <span class="subtitle-2 grey--text font-weight-light pl-5">
							{{getLabelValue()}}
						</span> -->
							<!-- <v-file-input
								v-model="selectedFiles"
								@change="handleEditFileChange"
								:label-value="getLabelValue"
								label="Прикрепить"
								class="border-none mr-8 d-flex"
								accept=".jpg, .jpeg, .png, .xlsx, .xls, .pdf, .doc, .docx, .pptx, .ppsx, .odp"
								hide-details
								flat
								chips
								multiple
								solo
								dense
								show-size
							></v-file-input> -->
							<v-row>
								<v-col cols="auto">
									<file-input
										v-model="selectedFiles"
										@change="handleEditFileChange"
									></file-input>
								</v-col>
								<v-col v-for="(image, i) in images" :key="i" cols="auto" class="position-relative image-container">
									<div class="image-wrapper">
										<img :src="image.url" class="notify-image">
										<v-btn
											@click="deleteLocalFile('image', i, image.id)"
											icon
											color="red"
											class="delete-btn"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</div>
								</v-col>
								<v-col v-for="(file, i) in item.files" :key="i" cols="auto" class="position-relative file-container">
									<div v-if="file.type !== 'image'" class="image-wrapper">
										<img src="@/assets/file.svg" class="notify-image">
										<p class="ma-0">
											<a @click="downloadFile(file)">{{ file.name }}</a>
										</p>
										<v-btn @click="deleteLocalFile(file.type, i, file.id)" icon color="red" class="delete-btn">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</div>
								</v-col>
							</v-row>
							<!-- <v-row>
								<v-col v-for="(file, i) in item.files" :key="i">
									<span v-if="file.type !== 'image'">
										<a @click="downloadFile(file)">{{ file.name }}</a>
										<v-btn @click="deleteLocalFile(file.type, i, file.id)" icon color="red">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</span>
								</v-col>
							</v-row> -->
						</v-col>

						<!-- <v-col cols="12">
							<template v-for="(image, i) in images">
								<img :src="image" :key="i" class="notify-image">
							</template>
						</v-col> -->

						<v-col v-if="alert" cols="12">
							<v-alert class="mb-0" :type="alert.color" dense outlined>
								{{ alert.message }}
							</v-alert>
						</v-col>
					</v-row>
				</v-container>
				<!-- СОЗДАНИЕ -->
				<v-container v-else>
					<v-row>
					<!-- Тип уведомления -->
						<v-col class="pb-0 px-0" cols="12">
							<v-select
								v-model="item.type_id"
								placeholder="Выберите тип уведомления"
								:menu-props="{ offsetY: true }"
								class="border-all"
								solo
								dense
								hide-details
								flat
								required
								item-value="sys_name"
								item-text="name"
								:items="[...notificationTypes]"
							></v-select>
						</v-col>
						<v-col v-if="base_role === 'dispatcher'" class="pb-0 px-0" cols="12">
							<v-select
								v-model="company_id"
								placeholder="Выберите УК"
								:menu-props="{ offsetY: true }"
								class="border-all"
								solo
								dense
								hide-details
								flat
								required
								item-value="id"
								item-text="name"
								:items="kskListConfirm"
							></v-select>
						</v-col>
						<v-col class="pb-0 px-0" cols="12">
							<combobox
								v-if="base_role !== 'dispatcher'"
								v-model="item.houses_ids"
								placeholder="Выберите адреса"
								:items="houses"
								@select-all="item.houses_ids = $event"
							/>
							<combobox
								v-else
								v-model="item.houses_ids"
								placeholder="Выберите адреса"
								:items="dispatcherHouses"
								:disabled="!company_id && !isHousesLoaded"
								@select-all="item.houses_ids = $event"
							/>
							<!-- <v-select
								v-model="house_ids"
								placeholder="Выберите адрес"
								class="border-all"
								solo
								dense
								hide-details
								flat
								multiple
								required
								item-value="id"
								item-text="apartment_address"
								:items="houses"
							>
							</v-select> -->
						</v-col>
						<!-- Тема уведомления -->
						<v-col class="px-0 pb-0" cols="12">
							<v-text-field
								v-model="item.name"
								placeholder="Тема уведомления"
								class="border-all"
								solo
								dense
								hide-details
								flat
								required
							></v-text-field>
						</v-col>
						<!-- Текст уведомления -->
						<v-col class="px-0" cols="12">
							<v-textarea
								v-model="item.description"
								class="border-all"
								height="200px"
								no-resize
								solo
								hide-details
								flat
								dense
							></v-textarea>
						</v-col>
						<!-- Файлы -->
						<v-col class="px-0" cols="12">
							<v-row>
								<v-col cols="auto">
									<file-input
										v-model="selectedFiles"
										@change="handleEditFileChange"
									></file-input>
								</v-col>
								<v-col v-for="(image, i) in images" :key="i" cols="auto" class="position-relative image-container">
									<div class="image-wrapper">
										<img :src="image.url" class="notify-image">
										<v-btn
											@click="deleteLocalFile('image', i, image.id)"
											icon
											color="red"
											class="delete-btn"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</div>
								</v-col>
								<v-col v-for="(file, i) in item.files" :key="i" cols="auto" class="position-relative file-container">
									<div v-if="file.type !== 'image'" class="image-wrapper">
										<img src="@/assets/file.svg" class="notify-image">
										<p class="ma-0">
											<a @click="downloadFile(file)">{{ file.name }}</a>
										</p>
										<v-btn @click="deleteLocalFile(file.type, i, file.id)" icon color="red" class="delete-btn">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</div>
								</v-col>
							</v-row>
						<!-- <span class="subtitle-2 grey--text font-weight-light pl-5">
							{{getLabelValue()}}
						</span> -->
							<!-- <v-file-input
								v-model="selectedFiles"
								@change="handleFileChange"
								:label-value="getLabelValue"
								label="Прикрепить"
								class="border-none mr-8 d-flex"
								accept="image/jpg, image/jpeg, image/png, .pdf, .doc, .docx, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								hide-details
								flat
								chips
								multiple
								solo
								dense
								show-size
							></v-file-input> -->
						</v-col>

						<!-- <v-col cols="12">
							<template v-for="(image, i) in images">
								<img :src="image" :key="i" class="notify-image">
							</template>
						</v-col> -->

						<v-col v-if="alert" cols="12">
							<v-alert class="mb-0" :type="alert.color" dense outlined>
								{{ alert.message }}
							</v-alert>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions class="pt-0 pb-5 d-flex justify-end">
				<Button
					v-if="toEdit && !isEditing"
					color="red"
					text
					@click.native="showModalConfirm = true"
				>
					Удалить
				</Button>

				<Button v-if="toEdit && !isEditing" :loading="isSaving" @click.native="isEditing = true">
					Редактировать
				</Button>
				<Button color="grey" text v-if="toEdit && isEditing" :loading="isSaving" @click.native="isEditing = false">
					Отмена
				</Button>
				<Button v-if="isEditing" :loading="isSaving" @click.native="handleSave">
					Сохранить
				</Button>

				<Button
					v-if="!toEdit"
					:loading="isCreating"
					@click.native="createNotification"
				>
					Отправить
				</Button>
			</v-card-actions>
		</v-card>

		<ModalConfirm
			v-if="showModalConfirm"
			:title="'Удаление уведомления'"
			@close-modal="closeConfirmModal"
		>
			<template #body>
				<span>
					Уведомление <b>{{ notification.title }}</b> будет удалено
				</span>
			</template>
		</ModalConfirm>
		<!-- Модальное окно -->
		<ModalWarning
			v-if="showModalWarning"
			:title="showModalWarning.title"
			:text="showModalWarning.text"
			@close-modal="closeModal"
		/>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Combobox from '@/components/app/Combobox.vue';
import FilesList from "@/components/app/FilesList";
import ModalConfirm from "@/components/app/ModalConfirm";
import ModalWarning from "@/components/app/ModalWarning";
import ImagesList from "@/components/app/ImagesList";
import FileInput from '@/components/app/FileInput.vue';
import NotificationsTableModalPersonal from "@/components/NotificationsTableModalPersonal";

export default {
	name: "NotificationsTableModal",

	components: {
		Combobox,
		FilesList,
		FileInput,
		ModalConfirm,
		ModalWarning,
		ImagesList,
		NotificationsTableModalPersonal,
	},

	props: {
		toEdit: {
			validator: e => e === null || typeof e === "object",
			defualt: () => null,
		},
	},

	data() {
		return {
			alert: null,
			dialog: true,
			loading: true,
			isEditing: false,
			isHousesLoaded: false,
			isCreate: true,
			notificationTypes: [],
			selectAllHouses: false,
			items: [],
			dispatcherHouses: [],
			images: [],
			imagesInfo: [],
			files: [],
			filesEdit: [],
			item: {
				name: '',
				houses_ids: '',
				description: '',
				visibility: 'house',
				type_id: '',
				files: []
			},
			company_id: null,
			deletedFiles: [],
			selectedFiles: [],
			notification: {
				houses_ids: [],
				description: "",
				name: "",
			},
			rules: {
				files: [
					v =>
						v.reduce((acc, cur) => acc + cur.size, 0) < 10485760 ||
						"Размер должен быть менее 10 MB!",
				],
			},
			isSaving: false,
			isCreating: false,
			showModalWarning: false,
			showModalConfirm: false,
		};
	},
	watch: {
		'company_id'() {
			if(this.base_role === 'dispatcher') {
				this.getHousesList()
			}
		},
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
			kskId: "auth/GET_KSK_ID",
			userId: "auth/GET_USER_ID",
			base_role: "auth/GET_USER_ROLE",
			kskListConfirm: "catalog/GET_KSK_LIST_CONFIRM",
		}),
	},

	methods: {
		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
		getHousesList() {
			this.isHousesLoaded = false
			return this.$api.dispatcher
				.load_houses({forget_cache: true, ksk_ids: [this.company_id]}).then(res => {
					this.dispatcherHouses = res;
					
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.isHousesLoaded = true
				});
		},
		handleEditFileChange(event) {
			console.log('eve', event)
			console.log('selecte', this.selectedFiles)
			if (!event.length) {
				this.item.files = [];
				this.selectedFiles = [];
				return;
			} 
			event.forEach((file) => {
				this.selectedFiles.push(file)
			});
			
			// if (event.length > 1) {
			// 	// Если выбрано более одного файла, обработайте только первый файл
			// 	const firstFile = event[0];
			// 	this.selectedFiles = [firstFile];
			// } else {
			// 	// Если выбран только один файл, обработайте его
			// 	this.selectedFiles = event;
			// }

			
			console.log('this.selectedFiles', this.selectedFiles)

			this.selectedFiles.forEach((file) => {
				console.log('file', file);
				const imageExists = this.images.some(image => image.name === file.name);
				const fileExists = this.item.files.some(existingFile => existingFile.name === file.name);

				if (!imageExists && !fileExists) {
					if (file.type.includes('image')) {
						const reader = new FileReader();
						reader.onload = (e) => {
							this.images.push({ url: e.target.result, name: file.name });
						};
						reader.readAsDataURL(file);
					} else {
						console.log('file is not an image');
						this.item.files.push({ name: file.name, size: file.size, type: file.type });
					}
				} else {
					console.log('Duplicate file not added:', file.name);
				}
			});

				// Фильтруем выбранные файлы, чтобы убедиться, что они не дублируются
			//   const filteredFiles = Array.from(this.selectedFiles).filter((file) => {
			// 	const isDuplicate = this.item.files.some(
			// 		(existingFile) => existingFile.name === file.name && existingFile.size === file.size
			// 	);
			// 	return !isDuplicate;
			// });
			// console.log('filteredFiles', filteredFiles)
			// // Обновление this.selectedFiles только уникальными файлами
			// this.selectedFiles = filteredFiles.map((file) => new File([file], file.name));
		},
		handleFileChange(event) {
			if(!event.length) {
				this.item.files = []
				return this.selectedFiles = []
			}
      const newFiles = Array.from(event);
			const filteredFiles = newFiles.filter((file) => {
        return !this.item.files.some((image) => image.name === file.name && image.size === file.size);
      });
      this.item.files.push(...filteredFiles);
      this.selectedFiles = this.item.files.map((file) => new File([file], file.name));
		},
		getLabelValue() {
      if (this.item.files.length === 0) return '';
      if (this.item.files.length === 1) return this.item.files[0].name;
      return `выбрано файлов - ${this.item.files.length}`;
    },
		getNotification() {
			return this.$api.ksk
				.get_notification(this.toEdit.id)
				.then(res => {
					this.item = res;
					if(!this.item.for_company && this.item.type.id === this.notificationTypes[0].id) {
						this.notification.type_id = 'notification_tenants'
					} else if(this.item.for_company && this.item.type.id === this.notificationTypes[1].id) {
						this.notification.type_id = 'notification_employees'
					} else if(!this.item.for_company && this.item.type.id === this.notificationTypes[2].id) {
						this.notification.type_id = 'reports'
					}
					this.item.type_id = this.item.type.id
					this.notification.name = this.item.name
					this.notification.description = this.item.description
					// this.notification.for_company = this.item.for_company
					if(this.base_role === 'dispatcher') {
						this.company_id = this.item.company.id
					}
					this.notification.houses_ids = this.item.houses
					this.item.files.forEach(element => {
						if(element.type === 'image') {
							this.loadServerImage(element)
							this.imagesInfo.push(element)
						}
						this.files.push(element.id)
					});
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async downloadFile(file) {
			const response = await this.$api.ksk.load_file(file.id);
			const blob = new Blob([response], { type: 'application/octet-stream' });
			const blobUrl = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = file.name;
			link.click();
			URL.revokeObjectURL(blobUrl);
		},
		async loadServerImage(avatar, showDefault = true) {
      if (!avatar && showDefault) {
        this.images.push("@/assets/user.svg");
        return; 
      }

      if (avatar?.id) {
        try {
          const response = await this.$api.ksk.load_file(avatar.id);
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const blobUrl = URL.createObjectURL(blob);
          this.images.push({url: blobUrl, id: avatar.id});
        } catch (error) {
          console.error('Ошибка при загрузке изображения:', error);
          this.images.push("@/assets/user.svg");
        }
      } else {
        this.imageSrc = require("@/assets/user.svg");
      }
    },
		deleteLocalFile(type, index, id) {
			console.log('id', id)
			if(id) this.deletedFiles.push(id)
			const fileIndex = this.files.findIndex(file => file === id);
			if (fileIndex !== -1) {
				this.files.splice(fileIndex, 1);
			}
			if(type === 'image') {
				this.images.splice(index, 1);
			} else {
				this.item.files.splice(index, 1);
			}
		},
		async deleteFile(id) {
			try {
				await this.$api.ksk.delete_files(id)
			} catch (error) {
				console.error('Ошибка при удалении изображения:', error);
				this.alert = {
					color: 'error',
					message: 'Ошибка при удалении изображения:' + error,
				};
			}
		},
		async closeConfirmModal(isConfirmed) {
			this.showModalConfirm = false;

			if (!isConfirmed) return;

			try {
				await this.$api.ksk.delete_notification(this.item.id);
				this.showModalWarning = {
					title: "Уведомление",
					text: `Уведомление ${this.item.name} удалено`,
				};
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Не удалось создать уведомление",
				};
			}
		},

		customValidation() {
			let message;
			if (!this.item.type_id) {
				message = "Выберите тип уведомления";
			} else if (!this.toEdit && !this.item.houses_ids.length) {
				message = "Выберите адрес";
			} else if (this.toEdit && !this.notification.houses_ids) {
				message = "Укажите адрес";
			} else if (!this.toEdit && !this.item.name) {
				message = "Введите тему уведомления";
			} else if (!this.toEdit && !this.item.description) {
				message = "Введите сообщение уведомления";
			} else if (this.toEdit && !this.notification.name) {
				message = "Введите тему уведомления";
			} else if (this.toEdit && !this.notification.description) {
				message = "Введите сообщение уведомления";
			} 

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
			}

			return !message;
		},

		async createNotification() {
			this.alert = null;

			if (!this.customValidation()) return;
			console.log('this.selectedFiles', this.selectedFiles)
			this.isCreating = true;
			
			if(this.selectedFiles.length) {
				this.files = []
				for(let i = 0; i < this.selectedFiles.length; i++) {
					await this.uploadFiles(this.selectedFiles[i])
				}
				if(!this.files.length) {
					this.alert = {
						color: 'error',
						message: 'Не удалось загрузить файл',
					};
					this.files = []
					this.item.files = []
					this.selectedFiles = []
					this.isCreating = false;
					return
				}
			}

			try {
				this.isCreating = true;
				if(this.base_role === 'dispatcher') {
					// const house = this.houses.find(item => item.id === this.house_ids[0]);
					// console.log('this.house_ids[0]', this.house_ids[0])
					// console.log('house', house)
					this.item.company_id = this.company_id
				} else {
					this.item.company_id = this.kskId
					this.item.selected_ksk_id = this.kskId
				}
				if(this.item.type_id === 'notification_employees') {
					this.item.for_company = true
				} else {
					this.item.for_company = false
				}
				this.item.type_id = this.notificationTypes.find(e => e.sys_name === this.item.type_id).id
				this.item.notification_files = this.files
				this.item.user_id = this.userId
				this.item.created_user_id = this.userId
				let params = { ...this.item };
				delete params.files; 
				params.houses_ids = this.item.houses_ids
				console.log('params', params)
				console.log('this.item', this.item)
				await this.$api.ksk.create_notification(params);
				this.showModalWarning = {
					title: "Уведомление",
					text: "Ваше уведомление успешно отправлено",
				};
			} catch (error) {
				console.log('err', error)
				this.alert = {
					color: "error",
					message: error.message || "Не удалось создать уведомление",
				};
			} finally {
				this.isCreating = false;
			}
		},

		async handleSave() {
			this.alert = null;

			if (!this.customValidation()) return;

			// this.notification.houses_ids = [this.notification.house_id];
			if(this.selectedFiles.length) {
				for (let i = 0; i < this.selectedFiles.length; i++) {
					console.log('selectedFile', this.selectedFiles[i])
					console.log('this.item.files', this.item.files)
					const selectedFile = this.selectedFiles[i];
					const isFileExists = this.item.files.some((file) => file.name === selectedFile.name);
					console.log('isFileExists', isFileExists)
					if (!isFileExists) {
						console.log('load')
						await this.uploadFiles(selectedFile);
					}
				}
				if(!this.files.length) {
					this.alert = {
						color: 'error',
						message: 'Не удалось загрузить файл',
					};
					this.files = []
					this.item.files = []
					this.selectedFiles = []
					this.isCreating = false;
					return
				}
			}
				
			try {
				this.isSaving = true;

				if (this.deletedFiles.length) {
					this.deletedFiles.forEach(el => {
						this.deleteFile(el);
					});
				}
				this.notification._method = "patch"
				if(this.notification.type_id === 'notification_employees') {
					this.notification.for_company = true
				} else {
					this.notification.for_company = false
				}
				this.notification.type_id = this.notificationTypes.find(e => e.sys_name === this.notification.type_id).id
				this.notification.company_id = this.kskId ? this.kskId : this.item.company_id
				this.notification.notification_files = this.files
				await this.$api.ksk.edit_notification({
					id: this.item.id,
					data: this.notification
				});

				this.alert = {
					type: "success",
					message: "Изменения сохранены",
				};

				this.$emit("close-modal");
			} catch (error) {
			} finally {
				this.isSaving = true;
			}
		},
		async uploadFiles(file) {
			try {
				let data = {
					file: file
				}
				const formData = data.toFormData();
				const res = await this.$api.ksk.load_files(formData);
				this.files.push(res.id)
				console.log('this.files push', this.files)
			} catch (error) {
				console.log('err', error)
				this.alert = {
					color: "error",
					message: error.message || "Неподдерживаемый файл",
				};
			}

		},
	},
	async created() {
		if (this.toEdit) {
			this.getNotification()
			this.isCreate = false
		} else {
			this.loading = false
		}
		this.notificationTypes = [
			{
				id: this.catalog.notification_types.find(item => item.sys_name === 'notifications').id,
				sys_name: 'notification_tenants',
				name: 'Оповещение для жителей'
			},
			{
				id: this.catalog.notification_types.find(item => item.sys_name === 'notifications').id,
				sys_name: 'notification_employees',
				name: 'Оповещение для работников'
			},
			{
				id: this.catalog.notification_types.find(item => item.sys_name === 'reports').id,
				sys_name: 'reports',
				name: 'Отчет для жителей'
			}
		];
	},
};
</script>

<style>
.chips-custom .v-chip--select {
	background: #F5F5F5 !important;
	border: 1px solid #E4E7EA !important;
	border-radius: 4px !important;
	padding: 0px 8px 0px 8px !important;
	margin-top: 5px !important;
}
.pointer-none {
	pointer-events: none;
}
/* .chips-custom .v-select__selections {
	margin-bottom: 5px !important;
} */
.chips-custom .v-input__slot {
	padding: 0 !important;
}
.custom-dark {
	color: #363636 !important;
}
</style>

<style scoped>
.notify-name {
	font-weight: 400;
	font-size: 21px;
}

.notify-image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.image-wrapper {
  position: relative;
  display: inline-block;
}
.delete-btn {
  display: none;
  position: absolute;
	top: -15px;
	right: -15px;

}

.image-container {
	max-width: 150px;
	height: 113px;
	display: flex;
}
.file-container {
	max-width: 150px;
	height: 85px;
	display: flex;
}

.image-container:hover .delete-btn {
  display: block;
}
.file-container:hover .delete-btn {
  display: block;
}
</style>
